'use client';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { getCookie, setCookie } from 'cookies-next';

let refreshTokenPromise: Promise<string> | null = null;

const axiosApi = axios.create({
  // baseURL: API_URL,
  // baseURL: 'https://api.chattersocial.io',
  baseURL: 'https://rest-api.gcp.chattersocial.io',
  // baseURL: 'http://192.168.0.106:3000',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosRetry(axiosApi, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) => {
    // if retry condition is not specified, by default idempotent requests are retried
    const resp_status = error.response?.status ?? 500;
    return resp_status >= 500;
  },
});

const refreshAccessToken = async () => {
  const refreshTokenCookie = getCookie('refresh_token');

  const response = await axiosApi.post('/auth/refresh', {
    refresh_token: refreshTokenCookie,
  });
  const { access_token, refresh_token } = response.data;

  setCookie('access_token', access_token, {
    sameSite: true,
    secure: true,
  });
  setCookie('refresh_token', refresh_token, {
    sameSite: true,
    secure: true,
  });
  return access_token;
};

// Request interceptor for API calls
axiosApi.interceptors.request.use(
  async (config) => {
    if (
      config.url?.startsWith('/register') ||
      config.url?.startsWith('/auth/login') ||
      config.url?.startsWith('/admin/login') ||
      config.url?.startsWith('/auth/refresh')
    ) {
      return config;
    }

    const accessTokenCookie = getCookie('access_token');

    config.headers.Authorization = `Bearer ${accessTokenCookie}`;
    return config;
  },
  (error) => {
    const e = error as AxiosError;
    console.log('[AXIOS ERRRROR URL]', e.response?.request);
    console.log('[AXIOS ERRRROR]', e.response?.data);

    Promise.reject(error);
  },
);
axiosApi.interceptors.request.use((request) => {
  console.log(`[API Request] ${request.method?.toUpperCase()} ${request.url}`);
  return request;
});
// Response interceptor for API calls
axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshAccessToken().then((token) => {
          refreshTokenPromise = null;
          return token;
        });
      }

      return refreshTokenPromise.then((token: string) => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        return axiosApi.request(originalRequest);
      });
    }

    const e = error as AxiosError;
    console.log('[AXIOS ERRRROR URL]', e.response?.request._url);
    console.log('[AXIOS ERRRROR]', e.response?.data);
    return Promise.reject(error);
  },
);

export default axiosApi;
